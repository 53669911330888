'use client';

import React, { useEffect } from 'react';

import Image from 'next/image';

import { usePictureViewerContext } from 'providers/picture-viewer-provider';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { styles } from '.';

type TProps = {
  //
  pictures: any;
};

const PictureViewer = (props: TProps) => {
  const { pictures } = props;

  const { setPictures, setSelector } = usePictureViewerContext();

  useEffect(() => {
    setPictures(pictures);
  }, [pictures, setPictures]);

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {pictures.map((picture: any, index: number) => (
          <li key={picture.id} className={styles.images.item}>
            <Image
              src={handleUseStrapiImageUrl(picture.url)}
              alt={picture.alternativeText ?? 'picture'}
              className={styles.images.image}
              fill
              onClick={() => setSelector(index)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PictureViewer;
