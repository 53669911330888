'use client';
import Image from 'next/image';

import {
  BlocksRenderer,
  type BlocksContent,
} from '@strapi/blocks-react-renderer';

import { toClassName } from 'helpers';

const RichText = ({
  content,
  className,
}: {
  readonly content: BlocksContent;
  className?: string;
}) => {
  if (!content) return null;
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        image: ({ image }) => {
          return (
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
              alt={image.alternativeText || ''}
            />
          );
        },
        paragraph: ({ children }) => (
          <p
            className={toClassName(
              'text-white text-sm flex flex-col gap-y-4 text-center md:text-left leading-6 sm:leading-7',
              className,
            )}
          >
            {children}
          </p>
        ),
        heading: ({ level, children }) => {
          let component;

          switch (level) {
            case 1:
              component = <h1 className="text-xl font-semibold">{children}</h1>;
              break;
            case 2:
              component = <h2 className="text-lg font-semibold">{children}</h2>;
              break;
            case 3:
              component = <h3 className="text-md font-semibold">{children}</h3>;
              break;
          }

          return component;
        },
        link: ({ url, children }) => {
          return (
            <a
              className={toClassName(
                'text-primary-500 hover:underline',
                className,
              )}
              href={url}
              target="_blank"
            >
              {children}
            </a>
          );
        },
      }}
    />
  );
};

export default RichText;
